<template>
  <v-container>
    <h1> <textloader-component textKey="ProgramuppläggRubrik"/> </h1>
    <paragraph-component> <textloader-component textKey="ProgramuppläggBrödtext"/> </paragraph-component>
    <v-card class="my-4" max-width="700">
      <v-card-text>
        <h3>Beräkningsteknik</h3>
      </v-card-text>
      <v-img
      src="@/assets/Information/Program/beräkning.jpg"
      :height= "150"
      ></v-img>
      <v-card-actions>
        <v-btn
         icon
          @click="reveal_1 = !reveal_1"
        >
          <v-icon>{{ reveal_1 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-card-actions>
      <v-expand-transition>
        <div v-show="reveal_1">
          <v-divider></v-divider>
          <v-card-text>
            <paragraph-component>
              <textloader-component textKey="ProgramuppläggBeräkning"/>
            </paragraph-component>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>

    <v-card class="my-4" max-width="700">
      <v-card-text>
        <h3> Inbyggda system </h3>
      </v-card-text>
      <v-img
      src="@/assets/Information/Program/inbyggda.jpg"
      :height= "150"
      ></v-img>
      <v-card-actions>
        <v-btn
         icon
          @click="reveal_2 = !reveal_2"
        >
          <v-icon>{{ reveal_2 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-card-actions>
      <v-expand-transition>
        <div v-show="reveal_2">
          <v-divider></v-divider>
          <v-card-text>
            <paragraph-component>
              <textloader-component textKey="ProgramuppläggInbyggda"/>
            </paragraph-component>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>

    <v-card class="my-4" max-width="700">
      <v-card-text>
        <h3> Tillämpad fysik </h3>
      </v-card-text>
      <v-img
      src="@/assets/Information/Program/tillämpad_fysik.jpg"
      :height= "150"
      ></v-img>
      <v-card-actions>
        <v-btn
         icon
          @click="reveal_3 = !reveal_3"
        >
          <v-icon>{{ reveal_3 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
        </v-btn>
      </v-card-actions>
      <v-expand-transition>
        <div v-show="reveal_3">
          <v-divider></v-divider>
          <v-card-text>
            <paragraph-component>
              <textloader-component textKey="ProgramuppläggTillämpad"/>
            </paragraph-component>
          </v-card-text>
        </div>
      </v-expand-transition>
    </v-card>
  </v-container>
</template>

<script>
import ParagraphComponent from '@/components/ParagraphComponent'
import TextloaderComponent from '@/components/TextloaderComponent'
export default {
  components: {
    ParagraphComponent,
    TextloaderComponent
  },
  data: () => ({
    reveal_1: false,
    reveal_2: false,
    reveal_3: false
  })
}
</script>
